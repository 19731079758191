import React from "react";
import { MdOutlineStarPurple500, MdOutlineStarHalf } from "react-icons/md";
import { FaCartPlus } from "react-icons/fa";
import Menu from "../components/Menu";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCartProduct } from "../redux/cartSlice";

const MenuPage = () => {
  const productItem = useSelector(state => state.productItem.productItem);
  const params = useParams();
  const navigate = useNavigate();

  const data = productItem.filter(product => product.id === params.productId)[0];

  const dispatch = useDispatch();

  const handleCartProduct = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setCartProduct(data));
  };

  const handleBuyProduct = (e) => {
    handleCartProduct(e);
    navigate("/cart");
  };

  return (
    <div className="h-full bg-gray-900 p-4 flex flex-col items-center">
      {/* Main Image */}
      <div className="w-full max-w-3xl mb-4">
        <div className="group w-full bg-white cursor-pointer">
          <img className="w-full rounded group-hover:scale-125 transition-all" src={data.imgURL} alt="Main Product" />
        </div>
      </div>

      {/* Other Images */}
      <div className="w-full max-w-3xl flex justify-center gap-4 mb-4">
        <div className="group w-24 md:w-32 bg-white cursor-pointer">
          <img className="w-full rounded group-hover:scale-125 transition-all" src={data.imgURL1} alt="Product Image 1" />
        </div>
        <div className="group w-24 md:w-32 bg-white cursor-pointer">
          <img className="w-full rounded group-hover:scale-125 transition-all" src={data.imgURL2} alt="Product Image 2" />
        </div>
        <div className="group w-24 md:w-32 bg-white cursor-pointer">
          <img className="w-full rounded group-hover:scale-125 transition-all" src={data.imgURL3} alt="Product Image 3" />
        </div>
      </div>

      {/* Size Buttons */}
      
      {/* Product Info */}
      <div className="w-full max-w-3xl text-center">
        <h1 className="text-white font-bold capitalize text-xl md:text-2xl">
          {data.title}
        </h1>
        <div className="text-green-600 flex items-center justify-center my-2">
          <MdOutlineStarPurple500 />
          <MdOutlineStarPurple500 />
          <MdOutlineStarPurple500 />
          <MdOutlineStarPurple500 />
          <MdOutlineStarHalf />
          <span className="text-white px-1">8.4</span>
          <span className="bg-white p-1 rounded-full"></span>
          <span className="text-white px-1">{data.category}</span>
        </div>
        <p className="text-xl font-semibold text-headingColor">
          <span className="text-green-600">₹</span>
          {data.price}
        </p>
        <div className="flex gap-4 w-full my-2 justify-center">
          <button className="flex justify-center items-center py-1 px-5 border-2 border-solid w-full max-w-[250px] whitespace-nowrap bg-green-600 text-black hover:bg-white hover:text-black font-semibold rounded" onClick={handleBuyProduct}>
            Buy
          </button>
          <button className="flex justify-center items-center py-1 px-5 border-2 border-solid w-full max-w-[200px] bg-white text-black hover:bg-green-600 hover:text-black font-semibold rounded" onClick={handleCartProduct}>
            <span className="whitespace-nowrap">Add to Cart</span>
            <FaCartPlus className="text-xl text-green-600 hover:text-red-600 cursor-pointer self-end mx-2" />
          </button>
        </div>
        <div>
          <h4 className="font-semibold text-white">Descriptions:</h4>
          <span className="text-white">{data.desc}</span>
        </div>
      </div>

      {/* Related Products Menu */}
      <Menu heading={"Related Products"} filtercategory="category" filterbyProps="GearHead" />
    </div>
  );
};

export default MenuPage;
