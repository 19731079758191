import React from 'react'

const Logo = () => {
    return (
        <div className='w-full h-full ' >
            
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
width="120" height="50" viewBox="0 0 356 200 " classname = "css-1j8o68f"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.10, written by Peter Selinger 2001-2011
</metadata>
<g transform="translate(0.000000,231.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M1074 2124 c3 -9 6 -24 6 -35 0 -19 10 -19 337 -17 l337 3 3 33 4 32
-347 0 c-311 0 -346 -2 -340 -16z"/>
<path d="M1164 1995 c3 -11 6 -25 6 -32 0 -9 64 -12 245 -12 l245 -1 10 26 c6
14 10 28 10 32 0 4 -117 7 -261 7 -255 0 -260 0 -255 -20z"/>
<path d="M1196 1893 c-1 -2 -1 -16 2 -32 l4 -30 219 0 c163 0 219 3 219 12 0
7 3 21 6 32 5 20 0 20 -221 20 -125 0 -228 -1 -229 -2z"/>
<path d="M1210 1263 c0 -285 -3 -535 -6 -555 l-7 -38 42 0 41 0 0 555 0 555
-35 0 -35 0 0 -517z"/>
<path d="M1384 1771 c-2 -2 -4 -251 -4 -553 l0 -548 40 0 40 0 0 554 0 555
-36 -2 c-19 -1 -37 -4 -40 -6z"/>
<path d="M1560 1225 l0 -555 40 0 40 0 0 555 0 555 -40 0 -40 0 0 -555z"/>
<path d="M828 1319 c-27 -15 -22 -38 12 -57 40 -23 40 -46 0 -37 -19 4 -30 3
-30 -5 0 -15 26 -23 55 -16 38 10 35 54 -5 67 -46 17 -38 35 13 30 21 -3 23
15 1 23 -21 8 -26 7 -46 -5z"/>
<path d="M940 1316 c0 -9 8 -16 20 -16 17 0 20 -7 20 -50 0 -39 3 -50 16 -50
13 0 15 9 12 50 -2 42 0 50 15 50 9 0 17 5 17 10 0 6 -12 10 -27 10 -16 0 -38
3 -50 6 -17 5 -23 2 -23 -10z"/>
<path d="M1793 1268 c-23 -66 -21 -87 3 -53 17 23 42 21 61 -3 11 -15 12 -15
13 -1 0 31 -31 119 -43 119 -7 0 -22 -28 -34 -62z m47 0 c0 -11 -8 -18 -20
-18 -13 0 -18 5 -15 15 16 51 16 50 25 35 6 -8 10 -23 10 -32z"/>
<path d="M1919 1326 c-2 -3 -2 -31 1 -63 4 -43 9 -59 21 -61 39 -7 64 62 39
109 -9 17 -49 27 -61 15z m49 -41 c3 -12 0 -15 -10 -10 -10 6 -11 5 0 -6 16
-18 15 -36 -3 -43 -12 -4 -15 4 -15 40 0 32 4 44 13 42 6 -3 14 -13 15 -23z"/>
<path d="M2034 1305 c-31 -47 12 -127 50 -96 20 17 21 67 1 74 -8 4 -17 2 -20
-3 -4 -6 -1 -10 4 -10 6 0 11 -11 11 -25 0 -14 -4 -25 -9 -25 -17 0 -32 29
-29 57 2 23 8 28 31 28 15 -1 27 5 27 12 0 24 -49 14 -66 -12z"/>
<path d="M692 1263 l3 -58 38 -3 c42 -4 52 15 10 20 -35 4 -43 28 -9 28 31 0
35 17 4 22 -35 5 -34 28 1 28 17 0 31 5 31 10 0 6 -18 10 -40 10 l-41 0 3 -57z"/>
<path d="M693 608 c3 -7 32 -73 63 -145 32 -73 60 -133 64 -133 3 0 16 26 27
58 12 31 37 94 57 140 l35 82 -24 0 c-20 0 -28 -11 -54 -80 -17 -43 -31 -84
-31 -90 0 -32 -21 1 -54 85 -34 85 -41 95 -63 95 -16 0 -23 -4 -20 -12z"/>
<path d="M1023 470 l0 -140 84 0 c76 0 83 2 83 20 0 18 -7 20 -60 20 l-60 0 0
46 0 45 52 -2 c43 -3 54 0 56 14 3 14 -5 17 -52 17 l-56 0 0 40 0 40 60 0 c53
0 60 2 60 20 0 18 -7 20 -83 20 l-83 0 -1 -140z"/>
<path d="M1323 595 c-28 -20 -38 -62 -20 -89 8 -12 37 -32 66 -45 54 -26 71
-52 50 -77 -13 -16 -55 -15 -90 0 -11 5 -19 1 -23 -11 -10 -25 19 -43 70 -43
56 0 94 30 94 73 -1 42 -15 59 -76 90 -52 26 -68 45 -56 64 12 18 48 24 77 13
32 -12 52 4 33 27 -14 18 -99 16 -125 -2z"/>
<path d="M1550 590 c0 -17 7 -20 44 -20 l44 0 -2 -120 c-2 -119 -2 -120 21
-120 23 0 23 2 23 120 l0 120 45 0 c38 0 45 3 45 20 0 19 -7 20 -110 20 -103
0 -110 -1 -110 -20z"/>
<path d="M1870 470 c0 -139 0 -140 23 -140 22 0 22 2 22 140 0 139 0 140 -22
140 -23 0 -23 -1 -23 -140z"/>
<path d="M2081 592 c-24 -19 -32 -75 -13 -94 6 -6 33 -23 58 -37 58 -32 73
-53 55 -75 -15 -17 -67 -21 -90 -7 -17 11 -41 3 -41 -13 0 -18 45 -36 91 -36
76 0 118 80 67 127 -13 11 -42 30 -64 41 -48 24 -60 45 -38 67 18 18 33 19 70
5 34 -13 54 1 34 25 -18 21 -101 20 -129 -3z"/>
<path d="M1124 203 c-3 -10 -16 -40 -30 -66 -28 -52 -29 -80 -2 -53 22 21 67
21 84 -1 26 -36 26 -2 0 56 -14 32 -26 64 -26 70 0 18 -20 13 -26 -6z m29 -63
c4 -16 0 -20 -19 -20 -21 0 -23 2 -12 25 12 27 23 26 31 -5z"/>
<path d="M1250 210 c0 -5 9 -10 20 -10 18 0 20 -7 20 -65 0 -51 3 -65 15 -65
12 0 15 14 15 65 0 58 2 65 20 65 11 0 20 5 20 10 0 6 -25 10 -55 10 -30 0
-55 -4 -55 -10z"/>
<path d="M1430 145 c0 -43 4 -75 10 -75 6 0 10 14 10 30 0 38 12 38 43 -1 13
-17 30 -30 36 -28 9 3 5 13 -9 32 -18 23 -19 28 -6 33 21 8 29 39 16 64 -8 15
-21 20 -55 20 l-45 0 0 -75z m79 39 c2 -2 1 -10 -2 -19 -9 -22 -57 -20 -57 3
0 10 3 22 7 25 6 6 42 0 52 -9z"/>
<path d="M1622 145 c-3 -63 -1 -75 12 -75 13 0 16 13 16 75 0 53 -4 75 -12 75
-9 0 -14 -23 -16 -75z"/>
<path d="M1750 198 c0 -13 -3 -47 -6 -75 -8 -64 10 -73 20 -10 l7 42 20 -37
c24 -46 35 -47 57 -8 22 39 32 38 32 -5 0 -26 4 -35 16 -35 12 0 15 6 10 23
-4 12 -9 46 -13 75 -3 28 -9 52 -12 52 -3 0 -17 -25 -30 -55 -27 -63 -38 -62
-68 11 -20 48 -33 57 -33 22z"/>
</g>
</svg>
            
        </div>
    )
}

export default Logo