import React from "react";
import { useSelector } from "react-redux";
import DeliveryImage from "../assest/img/delivery.png";
import HeaderProduct from "../components/HeaderProduct";
import { headerData } from "../database/headerData";
const Home = () => {
  const notHaveData = new Array(7).fill(null)
    // console.log(notHaveData)

  const productItem = useSelector((state) => state.productItem.productItem);
  const productLoading = useSelector(
    (state) => state.productItem.productLoading
  );

  // console.log(productItem)
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 overflow-hidden p-2  md:p-4">
      <div className="flex-1 flex flex-col items-start bg-white bg-opacity-10 backdrop-blur-md rounded-xl justify-center gap-2 py-3">
        {/* <div className="flex items-center gap-2 justify-center bg-purple-200 py-1 px-3 rounded-full">
          <p className="text-sm font-semibold text-purple-800">🔥best trends</p>
          <div className="w-7 h-7 bg-white rounded-full overflow-hidden drop-shadow-xl">
            <img
              src={DeliveryImage}
              className="w-full h-full object-contain"
              alt="Delivery"
            />
          </div>
        </div> */}

        <p className="text-6xl sm:text-8xl lg:text-8xl font-extrabold italics tracking-wider text-transparent bg-teal bg-clip-text">
          Vestis Atrium
          <br></br>
          <span className="text-black text-2xl sm:text-2xl lg:text-4xl">
            {" "}
            The Next-Gen clothing solution
          </span>
        </p>

        {/* <p className="text-base text-white text-center md:text-left md:w-4/5">
          Welcome to Vestis Atrium
        </p> */}
        
        <br></br>
        <button
          type="button"
          className="space-x-2 bg-teal w-full md:w-auto px-6 py-4 rounded-lg hover:shadow-lg transition-all ease-in-out font-semibold  duration-300 flex justify-center md:justify-self-start hover:scale-110 "
        >
          Order Now
        </button>
      </div>

      <div className="p-10 md:pt-10  flex-1 designHomeRight h-full px-2 ">
        <div className="flex flex-wrap justify-center items-center gap-1 md:gap-6">
          {headerData &&
            headerData.map((el) => {
              return (
                <HeaderProduct
                  key={el.id}
                  id={el.id}
                  name={el.name}
                  img={el.img}
                  decs={el.decs}
                  price={el.price}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Home;
