import T1 from "../assest/img/i1.png"
import c3 from "../assest/img/c3.png"
import f1 from "../assest/img/f1.png"
import fi1 from "../assest/img/fi1.png"

export const headerData = [
    { id : "1673062197207" , name : "Grear head", decs : "automobile", price : "10", img : T1},
    { id : "1673062261431" , name : "Cryptex", decs : "programmer", price : "15", img : c3},
    { id : "1673062328128" , name : "Menace", decs : "gymwear", price : "7", img : f1},
    { id : "1673062408402" , name : "Weabu", decs : "anime", price : "18", img : fi1},
]

export const categories = [
    
    {id : "1", name : "Shirt-buttoned", url : "Shirt-buttoned"},
    {id : "2", name : "T-shirt", url : "T-shirt"},
    {id : "3", name : "shirt-sleeves", url : "shirt-sleeves"},
    {id : "4", name : "T-shirt-sleeves", url : "T-shirt-sleeves"},
    {id : "5", name : "Gymwear", url : "Gymwear"},
    {id : "6", name : "Hoodies", url : "Hoodies"},
    
    
]

export const niches = [
    
    {id : "1", name : "GearHead", url : "GearHead"},
    {id : "2", name : "Cryptex", url : "Cryptex"},
    {id : "3", name : "Menace", url : "Menace"},
    {id : "4", name : "Anime", url : "Anime"},
    
]

