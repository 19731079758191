import React, { useMemo } from "react";
import { FaCartPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setCartProduct } from "../redux/cartSlice";

const FeatureCard = ({ id, img, title, price }) => {
  const productItem = useSelector(state => state.productItem.productItem)
  // console.log(productItem)
  const dispatch = useDispatch()
  const cartProduct = useSelector(state => state.cartProduct)
  const data = productItem.filter(product => product.id === id,[])[0]

  const handleCartProduct = (e)=>{
    e.stopPropagation()
    e.preventDefault()
    dispatch(setCartProduct(data))  
  }

  return (
    <Link to={"/menu/" + id} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
      <div
        id={id}
        className="group w-full h-40 min-w-[250px] min-h-[200] max-w-[250px] bg-white bg-opacity-20 backdrop-blur-md hover:backdrop-blur-lg hover:bg-opacity-40 hover:drop-shadow-md mt-6 rounded-xl p-4 flex items-center hover:scale-105 transition-all mr-3 cursor-pointer"
      >
        <div className="w-fit  p-3 h-auto  group-hover:scale-110 transition-all relative  box-border">
          <img
            src={img} 
            alt={title}
            className="w-full h-auto object-cover my-3 box-border"
          />
        </div>
        <div className="py-3 flex h-full flex-col justify-end gap-1 w-full p-3">
          <h3 className="text-base md:text-base font-bold text-textColor">
            {title}
          </h3>
          <p className="text-base font-semibold text-black">
            <span className="text-base text-black">₹</span>
            {price}
          </p>
          <FaCartPlus className="text-xl text-purple-600 hover:text-purple-600 cursor-pointer self-end" onClick={handleCartProduct} />
        </div>
      </div>
    </Link>
  );
};

export default FeatureCard;
