import React from "react";

const Footer = () => {
  return (
    <div className="p-4 bg-opacity-0 backdrop-blur-lg bg-white bg-opacity-20 text-center text-white font-semibold shadow-lg">
      <h2>
        {" "}
        <a
          href="https://www.linkedin.com/in/itsamitprajapati/"
          className="hover:text-purple-600 hover:underline"
        >
          privacy policy
        </a>
      </h2>
      <h2>
        Made by{" "}
        <a
          href="https://www.linkedin.com/in/itsamitprajapati/"
          className="hover:text-purple-600 hover:underline"
        >
          terms and conditions
        </a>
      </h2>
    </div>
  );
};

export default Footer;
