import { initializeApp, getApp, getApps, } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import {getStorage} from "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyAdlO4MKb01Tg9jtcngNX5Rj_zvt7llDHQ",
    authDomain: "vestisatrium.firebaseapp.com",
    projectId: "vestisatrium",
    storageBucket: "vestisatrium.appspot.com",
    messagingSenderId: "305536934644",
    appId: "1:305536934644:web:804182fdbfe56702fab4a1",
    measurementId: "G-FVMSKR2VS2"
};

const app = getApps.length > 0 ? getApp() :  initializeApp(firebaseConfig);

const firestore = getFirestore(app)
const storage = getStorage(app)

export {app,firestore,storage}